<template>
  <b-card :title="$t('Banka Hesap Bilgilerimiz')">
    <b-row>
      <b-col
        v-for="bankAccount in bankAccounts"
        :key="bankAccount.id"
        xs="12"
        md="12"
        class="mb-2"
      >
        <b-list-group>
          <b-list-group-item v-if="bankAccount.title">
            <div class="font-weight-bold text-primary">
              Hesap Sahibi
            </div>
            <div>{{ bankAccount.title }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="bankAccount.bank">
            <div class="font-weight-bold text-primary">
              Banka
            </div>
            <div>{{ bankAccount.bank }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="bankAccount.branch_code">
            <div class="font-weight-bold text-primary">
              Şube Kodu / Şube
            </div>
            <div>{{ bankAccount.branch_code }} / {{ bankAccount.branch }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="bankAccount.account_code">
            <div class="font-weight-bold text-primary">
              Hesap No
            </div>
            <div>{{ bankAccount.account_code }}</div>
          </b-list-group-item>
          <b-list-group-item v-if="bankAccount.iban">
            <div class="font-weight-bold text-primary">
              IBAN No
            </div>
            <div>{{ bankAccount.iban }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  computed: {
    bankAccounts() {
      return this.$store.getters['appBankAccounts/getBankAccounts']
    },
  },
  created() {
    this.getBankAccounts()
  },
  methods: {
    getBankAccounts() {
      this.$store.dispatch('appBankAccounts/bankAccountsList')
    },
  },
}
</script>

<style scoped></style>
